import React from 'react';
import './HomepageRouteToPricing.css';
import { useNavigate } from "react-router-dom";
import { BiEuro } from "react-icons/bi";
import {SiRazorpay} from "react-icons/si"; /* Import Euro icon from react-icons */

function HomepageRouteToPricing() {
    const navigate = useNavigate();

    function handleRedirectToBetaalplan() {
        navigate('/betaalplan');
    }

    return (
        <div className="HomepageRouteToPricing">
            <div className="hp-route-content-pricing">
                <p className="hp-route-to-pricing">Maximaal profijt met een betaalplan die bij jou past.</p>
            </div>
            <button onClick={handleRedirectToBetaalplan} className="hp-route-betaalplan">
                {/*<BiEuro className="hp-route-betaalplan-icon" />*/}
                <SiRazorpay/>
                Betaalplan
            </button>
        </div>
    );
}

export default HomepageRouteToPricing;
