import React from 'react';
import './ActionRouteToPricing.css';
import { useNavigate } from "react-router-dom";
import { BiEuro } from "react-icons/bi";
import {SiRazorpay} from "react-icons/si"; /* Import Euro icon from react-icons */

function ActionRouteToPricing() {
    const navigate = useNavigate();

    function handleRedirectToBetaalplan() {
        navigate('/betaalplan');
    }

    return (
        <div className="ActionRouteToPricing">
            <button onClick={handleRedirectToBetaalplan} className="action-route-betaalplan">
                {/*<BiEuro className="action-route-betaalplan-icon" />*/}
                <SiRazorpay/>
                Betaalplan
            </button>
            <div className="action-route-to-pricing-content">
                <p className="action-route-to-pricing">Bekijk betaalplan mogelijkheden pagina </p>
            </div>

        </div>
    );
}

export default ActionRouteToPricing;