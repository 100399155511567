import React from 'react';
import './Packages.css';

function Packages() {
    return (
        <div className="Packages">
            <h1 className="package-heading">Ons Aanbod</h1>
            <div className="package-row-one">
                <div className="package-one">
                    <h1 className="package-title">Startende ondernemer</h1>
                    <h2 className="package-subtitle">Begin uw digitale reis met vertrouwen.</h2>
                    <p className="package-paragraph">Wij bieden startende ondernemers gepersonaliseerde oplossingen om een sterke basis te leggen in de digitale wereld. Onze diensten zijn ontworpen om uw specifieke behoeften en doelen te ondersteunen, zodat u succesvol kunt groeien.</p>
                    <li className="li-package-features-">
                        <ul className="ul-package-feature">Individuele begeleiding</ul>
                        <ul className="ul-package-feature">Effectieve digitale strategieën</ul>
                        <ul className="ul-package-feature">Toegang tot uitgebreide resources</ul>
                    </li>
                </div>

                <div className="package-two">
                    <h1 className="package-title">Software op maat</h1>
                    <h2 className="package-subtitle">Ontvang software die perfect aansluit bij uw wensen.</h2>
                    <p className="package-paragraph">Wij ontwikkelen maatwerk software om aan de unieke behoeften van uw bedrijf te voldoen. Ons team gebruikt de nieuwste technologieën om uw processen te optimaliseren en uw doelen te bereiken.</p>
                    <li className="li-package-features-">
                        <ul className="ul-package-feature">Op maat gemaakte oplossingen</ul>
                        <ul className="ul-package-feature">Moderne technologie</ul>
                        <ul className="ul-package-feature">Integratie met bestaande systemen</ul>
                    </li>
                </div>
            </div>

            <div className="package-row-two">
                <div className="package-three">
                    <h1 className="package-title">Interne Bedrijfssoftware</h1>
                    <h2 className="package-subtitle">Optimaliseer uw interne processen.</h2>
                    <p className="package-paragraph">Onze interne bedrijfssoftware is ontworpen om uw dagelijkse bedrijfsvoering te stroomlijnen. Wij bieden oplossingen die de efficiëntie verbeteren en de productiviteit verhogen.</p>
                    <li className="li-package-features-">
                        <ul className="ul-package-feature">Efficiënt beheer</ul>
                        <ul className="ul-package-feature">Productiviteitsverhoging</ul>
                        <ul className="ul-package-feature">Gebruiksvriendelijke interfaces</ul>
                    </li>
                </div>
                <div className="package-custom">
                    <h1 className="package-title">Consultancy</h1>
                    <h2 className="package-subtitle">Deskundig advies voor uw IT-projecten.</h2>
                    <p className="package-paragraph">Onze consultants bieden strategisch advies om uw IT-infrastructuur en softwareprojecten te optimaliseren. We helpen u bij het plannen, uitvoeren en beheren van uw IT-strategie.</p>
                    <li className="li-package-features-">
                        <ul className="ul-package-feature">Strategisch advies</ul>
                        <ul className="ul-package-feature">Projectbeheer</ul>
                        <ul className="ul-package-feature">Optimalisatie van IT-infrastructuur</ul>
                    </li>
                </div>






            </div>
        </div>
    );
}

export default Packages;
