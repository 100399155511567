import React from 'react';
import './ActionWebApp.css';
import { useNavigate } from 'react-router-dom';
import ActionRouteToPricing from "./ActionRouteToPricing";
import ActionRouteToConsultancy from "./ActionRouteToConsultancy";

function ActionWebApp() {
    const navigate = useNavigate();

    function handleRedirect() {
        navigate('/web-app/portfolio');
    }

    return (
        <div className="ActionWebApp">
            <div className="webapp-container">

                <h1>Web Applicatie</h1>
                <h2 className="webapp-title">Ontwikkeling, Onderhoud en/of Optimalisatie</h2>
                <p className="webapp-description">
                    Onze webapplicatie diensten helpen u bij het ontwikkelen van krachtige en responsieve webapplicaties die voldoen aan uw bedrijfsbehoeften. Ons ervaren team biedt volledige ondersteuning van concept tot implementatie.
                </p>
                <h3 className="webapp-subtitle">Onze Diensten</h3>
                <ul className="webapp-list">
                    <li>Volledig maatwerk in webapplicatieontwikkeling.</li>
                    <li>Responsief en mobielvriendelijk ontwerp.</li>
                    <li>Integratie met bestaande systemen en API's.</li>
                    <li>Onderhoud en ondersteuning van webapplicaties.</li>
                    <li>Optimalisatie voor prestaties en schaalbaarheid.</li>
                </ul>
                <h3 className="webapp-subtitle">Hoe te Beginnen</h3>
                <p className="webapp-description">
                    Volg deze stappen om te beginnen met onze webapplicatie diensten:
                </p>
                <ul className="webapp-list">
                    <li>Identificeer uw projectdoelen en vereisten.</li>
                    <li>Verzamel alle relevante documentatie en gegevens.</li>
                    <li>Maak een lijst van de belangrijkste functies die u nodig heeft.</li>
                    <li>Plan een overleg met ons team om uw behoeften te bespreken.</li>
                </ul>
                {/*<button*/}
                {/*    className="portfolio-button"*/}
                {/*    onClick={handleRedirect}*/}
                {/*>*/}
                {/*    Bekijk ons Portfolio*/}
                {/*</button>*/}
                <ActionRouteToConsultancy />

                <h3 className="webapp-subtitle">Privacy en Beveiliging</h3>
                <p className="webapp-description">
                    Uw gegevens en privacy zijn van groot belang voor ons. Alle informatie die tijdens onze samenwerking wordt gedeeld, wordt strikt vertrouwelijk behandeld en beveiligd.
                </p>
                <ActionRouteToPricing />

                <h3 className="webapp-subtitle">Prijzen</h3>
                <p className="webapp-description">
                    Onze prijzen zijn flexibel en kunnen worden aangepast aan uw specifieke behoeften. Voor een gedetailleerde offerte, neem contact met ons op.
                </p>
            </div>
        </div>
    );
}

export default ActionWebApp;
