import React from 'react';
import './App.css';
import Header from "./components/Header";
import Homepage from "./components/Homepage";
import {Route, Routes} from "react-router-dom";
import Footer from "./components/Footer";
import ActionConsultancy from "./components/ActionConsultancy";
import TechDictionary from "./components/TechDictionary";
import Rondleiding from "./components/Rondleiding";
// import TimeSelect from "./components/BookingApp/TimeSelect";
// import DateSelect from "./components/BookingApp/DateSelect";
import useScrollToTop from "./components/hooks/useScrollToTop";
import Pricing from "./components/Pricing";
import ActionWebApp from "./components/ActionWebApp";
import ActionArtificialIntelligence from "./components/ActionArtificialIntelligence";
import ActionAPI from "./components/ActionAPI";
import ExplainedAPI from "./components/ExplainedAPI";
import ExplainedWebApp from "./components/ExplainedWebApp";
import ExplainedArtificialIntelligence from "./components/ExplainedArtificialIntelligence";

function App() {
    useScrollToTop();

    return (
        <div>
            <Header />
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/rondleiding" element={<Rondleiding />} />


                <Route path="/tech-dictionary" element={<TechDictionary />} />
                {/*<Route path="/uitgelegd/web-app" element={<ExplainedWebApp />} />*/}
                {/*<Route path="/uitgelegd/artificial-intelligence" element={<ExplainedArtificialIntelligence />} />*/}
                {/*<Route path="/uitgelegd/api" element={<ExplainedAPI />} />*/}


                <Route path="/web-app" element={<ActionWebApp />} />
                <Route path="/artificial-intelligence" element={<ActionArtificialIntelligence />} />
                <Route path="/api" element={<ActionAPI />} />
                <Route path="/consultancy" element={<ActionConsultancy />} />

                <Route path="/betaalplan" element={<Pricing />} />




            </Routes>
            <Footer />
        </div>
    );
}

export default App;


//TODO
// {/*<Route path="/about" element={<AboutUs />} />*/}
// {/*<Route path="/contact" element={<Contact />} />*/}
// {/*<Route path="/information" element={<Information />} />*/}
//                {/*<Route path="/booking-app/date-select" element={<DateSelect />} />*/}
//                 {/*<Route path="/booking-app/time-select" element={<TimeSelect />} />*/}
//                 {/*<Route path="/price-page" element={<Pricing />} />*/}
//                {/*<Route path="/starters" element={<Starters />} />*/}
