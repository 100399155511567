import React from 'react';
import './ActionConsultancy.css';
import { useNavigate } from 'react-router-dom';
import ActionRouteToPricing from "./ActionRouteToPricing";
import ActionRouteToConsultancy from "./ActionRouteToConsultancy";

function ActionConsultancy() {
    const navigate = useNavigate();

    function handleRedirect() {
        navigate('/booking-app/date-select');
    }

    return (
        <div className="Consultancy">
            <div className="consultancy-container">
                <h2 className="consultancy-title">Consultancy Dienst</h2>
                <p className="consultancy-description">
                    Wij bieden consultancy diensten aan om u te helpen bij uw projecten. Ons team van experts staat klaar om technisch advies te geven, problemen op te lossen en strategieën te ontwikkelen die uw doelen ondersteunen.
                </p>
                <h3 className="consultancy-subtitle">Werkwijze</h3>
                <ul className="consultancy-list">
                    <li>Analyse van uw projectvereisten en doelstellingen.</li>
                    <li>Advies over de beste technische oplossingen en benaderingen.</li>
                    <li>Ontwikkeling van strategieën en implementatieplannen.</li>
                    <li>Ondersteuning bij probleemoplossing en optimalisatie.</li>
                    <li>Rapportage en evaluatie van de voortgang.</li>
                </ul>
                <h3 className="consultancy-subtitle">Voorbereiding op het Consult</h3>
                <p className="consultancy-description">
                    Om het meeste uit het consult te halen, raden wij aan dat u zich voorbereidt door:
                </p>
                <ul className="consultancy-list">
                    <li>Een overzicht te maken van uw projectdoelen en uitdagingen.</li>
                    <li>Eventuele relevante documentatie of informatie over uw project beschikbaar te stellen.</li>
                    <li>Specifieke vragen of problemen die u wilt bespreken op te schrijven.</li>
                    <li>De relevante stakeholders binnen uw organisatie op de hoogte te stellen van het consult en hun input te verzamelen.</li>
                </ul>
                {/*<button*/}
                {/*    className="make-a-booking-button"*/}
                {/*    onClick={handleRedirect}*/}
                {/*>*/}
                {/*    Boek Tijdslot*/}
                {/*</button>*/}

                <h3 className="consultancy-subtitle">Privacy</h3>
                <p className="consultancy-description">
                    Wij hechten veel waarde aan uw privacy en vertrouwelijkheid. Alle informatie die tijdens de consults wordt gedeeld, wordt strikt vertrouwelijk behandeld.
                </p>
                <ActionRouteToPricing />
                <h3 className="consultancy-subtitle">Facturatie beleid</h3>
                <p className="consultancy-description">
                    De uren die worden gefactureerd betreffen alleen de daadwerkelijke consultancy bezoeken. Uren besteed buiten de consultancy bezoeken, zoals voor voorbereiding, onderzoek en documentatie, worden niet in rekening gebracht.
                </p>
                <p className="consultancy-description">
                    Onze consultancy diensten zijn flexibel en kunnen worden aangepast aan uw specifieke behoeften.
                </p>
            </div>
        </div>
    );
}

export default ActionConsultancy;

//todo
// <div className="reason">
//     <h3>Consult</h3>
//     <p>Onze experts staan klaar om u te adviseren en te helpen met strategische beslissingen voor uw IT-infrastructuur en softwareprojecten.</p>
// </div>
