import React from 'react';
import './WhyChooseUs.css';

function WhyChooseUs() {
    return (
        <section className="why-choose-us" id="why-develop-bit">
            <h2>Onze Kernwaarden</h2>
            <div className="reasons">
                <div className="reason">
                    <h3>Betrouwbaarheid</h3>
                    <p>Onze eerlijke en betrouwbare dienstverlening zorgt ervoor dat u altijd op tijd en volgens
                        afspraak de beste oplossingen ontvangt, met een sterke focus op ethische waarden en
                        transparantie.</p>
                </div>
                <br/>
                <div className="reason">
                    <h3>Kwaliteit</h3>
                    <p>Bij Develop Bit streven we naar perfectie en leveren we software van de hoogste kwaliteit,
                        volledig aangepast aan uw specifieke behoeften.</p>
                </div>
                <br/>
                <div className="reason">
                    <h3>Innovatie</h3>
                    <p>Wij gebruiken de nieuwste technologieën en trends om innovatieve en efficiënte oplossingen te
                        bieden die uw bedrijf vooruit helpen.</p>
                </div>
                <br/>

            </div>
        </section>
    );
}

export default WhyChooseUs;


