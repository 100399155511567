import React from 'react';
import './Pricing.css';
import {FaCheck} from "react-icons/fa";
import { Link } from 'react-router-dom';
import HomepageRouteToConsultancy from "./HomepageRouteToConsultancy";
import ActionRouteToConsultancy from "./ActionRouteToConsultancy";

function Pricing() {
    return (
        <div className="Pricing">
            <h1 className="pricing-heading">Betaalplan Vrijheid</h1>
            <br/>
            <div className="pricing-row-one">
                    <div className="pricing-one">
                        <h1 className="pricing-title">Project Prijs</h1>
                        <h2 className="pricing-subtitle">Vaste prijs voor elk project.</h2>
                        <p className="pricing-paragraph">Deze prijsstructuur biedt een vaste prijs voor elk project, ideaal voor bedrijven die een duidelijk budget willen hebben zonder verrassingen.</p>
                        <li className="li-pricing-features">
                            <ul className="ul-pricing-feature">
                                <FaCheck className="pricing-check-item-icon"/>
                                <p className="ul-pricing-text">Vaste prijsafspraken</p>
                            </ul>
                            <ul className="ul-pricing-feature">< FaCheck className="pricing-check-item-icon" /> <p>Geen verborgen kosten</p></ul>
                            <ul className="ul-pricing-feature">< FaCheck className="pricing-check-item-icon" /> <p>Flexibele betalingsopties</p></ul>
                        </li>
                        {/*<Link to="/pricing" className="pricing-link">*/}
                        {/*    <p>Klik hier voor meer informatie</p>*/}
                        {/*</Link>*/}
                    </div>

                <div className="pricing-two">
                    <h1 className="pricing-title">Per gebruiker</h1>
                    <h2 className="pricing-subtitle">Betaal per gebruiker per maand.</h2>
                    <p className="pricing-paragraph">Deze optie is ideaal voor bedrijven die hun kosten willen baseren op het aantal actieve gebruikers, zodat ze alleen betalen voor wat ze gebruiken.</p>
                    <li className="li-pricing-features">
                        <ul className="ul-pricing-feature">< FaCheck className="pricing-check-item-icon" /> <p>Schaalbaar aantal gebruikers</p></ul>
                        <ul className="ul-pricing-feature">< FaCheck className="pricing-check-item-icon" /> <p>Maandelijkse facturatie</p></ul>
                        <ul className="ul-pricing-feature">< FaCheck className="pricing-check-item-icon" /> <p>Flexibele annulering</p></ul>
                    </li>
                    {/*<Link to="/pricing" className="pricing-link">*/}
                    {/*<p>Klik hier voor meer informatie</p>*/}
                    {/*</Link>*/}
                </div>
            </div>

            <div className="pricing-row-two">
                <div className="pricing-three">
                    <h1 className="pricing-title">Maandelijks plan</h1>
                    <h2 className="pricing-subtitle">Vaste maandelijkse kosten.</h2>
                    <p className="pricing-paragraph">Onze maandelijkse abonnementen bieden een consistente kostenstructuur, ideaal voor bedrijven die hun maandelijkse uitgaven willen beheren.</p>
                    <li className="li-pricing-features">
                        <ul className="ul-pricing-feature">< FaCheck className="pricing-check-item-icon" /> <p>Vaste maandelijkse prijs</p></ul>
                        <ul className="ul-pricing-feature">< FaCheck className="pricing-check-item-icon" /> <p>Onbeperkt gebruik</p></ul>
                        <ul className="ul-pricing-feature">< FaCheck className="pricing-check-item-icon" /> <p>Inclusief updates en support</p></ul>
                    </li>
                    {/*<Link to="/pricing" className="pricing-link">*/}
                    {/*    <p>Klik hier voor meer informatie</p>*/}
                    {/*</Link>*/}
                </div>
                <div className="pricing-custom">
                    <h1 className="pricing-title">Micro-Transacties</h1>
                    <h2 className="pricing-subtitle">Betaal per transactie.</h2>
                    <p className="pricing-paragraph">Deze optie is perfect voor bedrijven die kosten willen maken op basis van hun gebruik, ideaal voor seizoensgebonden of variabele transacties.</p>
                    <li className="li-pricing-features">
                        <ul className="ul-pricing-feature">< FaCheck className="pricing-check-item-icon" /> <p>Betaal per gebruik</p></ul>
                        <ul className="ul-pricing-feature">< FaCheck className="pricing-check-item-icon" /> <p>Geen vaste kosten</p></ul>
                        <ul className="ul-pricing-feature">< FaCheck className="pricing-check-item-icon" /> <p>Transparante kostenstructuur</p></ul>
                    </li>
                    {/*<Link to="/pricing" className="pricing-link">*/}
                    {/*    <p>Klik hier voor meer informatie</p>*/}
                    {/*</Link>*/}
                </div>
            </div>
            <div>
                <ActionRouteToConsultancy />
            </div>

        </div>
    );
}

export default Pricing;
