import React from 'react';
import './Footer.css';
import Logo from "../assets/Logo.png";
import SendWhatsAppMessage from "./SendWhatsAppMessage";
import SendFacebookMessage from "./SendFacebookMessage";
import SendEmailMessage from "./SendEmailMessage";
import RequestCallAppointment from "./RequestCallAppointment";

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-logo">
                    <img src={Logo} alt="Logo" />
                </div>
                <div className="footer-messages">
                    <SendWhatsAppMessage />
                    <SendFacebookMessage />
                    {/*<SendEmailMessage />*/}
                    <RequestCallAppointment />
                </div>
                <p>&copy; 2023 Develop Bit</p>
                <div className="footer-links">
                    <a href="/algemene-voorwaarden">Algemene voorwaarden</a>
                    <a href="/privacybeleid">Privacybeleid</a>
                    <a href="/disclaimer">Disclaimer</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

