import React from 'react';
import './Homepage.css';
import HeroSection from "./HeroSection";
import Packages from "./Packages";
import WhyChooseUs from "./WhyChooseUs";
import HomepageRouteToPricing from "./HomepageRouteToPricing";
import ActionRouteToConsultancy from "./ActionRouteToConsultancy";
import HomepageRouteToConsultancy from "./HomepageRouteToConsultancy";

function Homepage() {
    return (
        <div className="homepage-container">
            <HeroSection />
            <WhyChooseUs />
            <HomepageRouteToPricing />
            <Packages />
            <HomepageRouteToConsultancy />


        </div>
    );
}

export default Homepage;
