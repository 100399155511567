import React from 'react';
import './ActionArtificialIntelligence.css';
import { useNavigate } from 'react-router-dom';
import HomepageRouteToPricing from "./HomepageRouteToPricing";
import ActionRouteToPricing from "./ActionRouteToPricing";
import ActionRouteToConsultancy from "./ActionRouteToConsultancy";

function ActionArtificialIntelligence() {
    const navigate = useNavigate();

    function handleRedirect() {
        navigate('/ai-solutions');
    }

    return (
        <div className="ActionArtificialIntelligence">
            <div className="ai-container">
                <h1>Artificial Intelligence </h1>
                <h2 className="ai-title">Ontwikkeling, Onderhoud en/of Optimalisatie</h2>
                <p className="ai-description">
                    Onze AI-diensten helpen uw bedrijf te transformeren door geavanceerde machine learning en data-analyseoplossingen. Ons team van experts werkt samen met u om slimme en efficiënte AI-oplossingen te ontwikkelen die uw bedrijfsdoelen ondersteunen.
                </p>
                <h3 className="ai-subtitle">Onze Diensten</h3>
                <ul className="ai-list">
                    <li>Ontwikkeling en implementatie van machine learning modellen.</li>
                    <li>Data-analyse en voorspellende modellering.</li>
                    <li>Natural Language Processing (NLP) toepassingen.</li>
                    <li>Beeld- en spraakherkenningstechnologieën.</li>
                    <li>AI-gedreven automatisering en optimalisatie.</li>
                </ul>
                <h3 className="ai-subtitle">Hoe te Beginnen</h3>
                <p className="ai-description">
                    Volg deze stappen om te beginnen met onze AI-diensten:
                </p>
                <ul className="ai-list">
                    <li>Identificeer de problemen of uitdagingen die u met AI wilt oplossen.</li>
                    <li>Verzamel alle relevante data en documentatie.</li>
                    <li>Maak een lijst van de gewenste uitkomsten en doelen.</li>
                    <li>Plan een overleg met ons team om uw behoeften te bespreken.</li>
                </ul>
                {/*<button*/}
                {/*    className="ai-solutions-button"*/}
                {/*    onClick={handleRedirect}*/}
                {/*>*/}
                {/*    Bekijk AI Oplossingen*/}
                {/*</button>*/}
                <ActionRouteToConsultancy />

                <h3 className="ai-subtitle">Privacy en Beveiliging</h3>
                <p className="ai-description">
                    Uw gegevens en privacy zijn voor ons van het grootste belang. Alle informatie die tijdens onze samenwerking wordt gedeeld, wordt strikt vertrouwelijk behandeld en beveiligd.
                </p>
                <ActionRouteToPricing />
                <h3 className="ai-subtitle">Prijzen</h3>
                <p className="ai-description">
                    Onze prijzen zijn flexibel en kunnen worden aangepast aan uw specifieke behoeften. Voor een gedetailleerde offerte, neem contact met ons op. We adviseren u om ook op onze betaalpagina te kijken.
                </p>

            </div>
        </div>
    );
}

export default ActionArtificialIntelligence;
