import React, { useEffect, useState } from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import './Header.css';
// import Logo from '../assets/Logo.png';
import DevelopBitHeaderLogo from '../assets/DevelopBitHeaderLogo.png';
import {IoEnterOutline} from "react-icons/io5";


function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleLinkClick = (id) => {
        closeMenu();
    };

    function handleRedirectRondleiding() {
        closeMenu();
        navigate('/rondleiding');
    }

    return (
        <header className="m-header">

            <Link to="/" id="hero-section" className="title-and-menu-link">
                <img src={DevelopBitHeaderLogo} alt="Develop Bit Logo" className="header-logo" onClick={handleLinkClick}/>
            </Link>

            <div className="title-and-menu">
                <div className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <div className="bar"/>
                    <div className="bar"/>
                    <div className="bar"/>
                </div>
            </div>

            <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                <ul>
                    <li>
                        <Link to="/tech-dictionary"
                              className={`nav-link ${location.pathname === '/tech-dictionary' ? 'active' : ''}`}
                              onClick={() => handleLinkClick('')}>
                            TECH Woordenboek
                        </Link>
                    </li>
                    <li>
                        <Link to="/consultancy"
                              className={`nav-link ${location.pathname === '/consultancy' ? 'active' : ''}`}
                              onClick={() => handleLinkClick('')}>
                            Consultancy
                        </Link>
                    </li>
                </ul>
                <ul>
                    <li>
                        <Link to="/api"
                              className={`nav-link ${location.pathname === '/api' ? 'active' : ''}`}
                              onClick={() => handleLinkClick('')}>
                            API
                        </Link>
                    </li>
                    <li>
                        <Link to="/web-app"
                              className={`nav-link ${location.pathname === '/web-app' ? 'active' : ''}`}
                              onClick={() => handleLinkClick('')}>
                            Web- Applicatie
                        </Link>
                    </li>
                    <li>
                        <Link to="/artificial-intelligence"
                              className={`nav-link ${location.pathname === '/artificial-intelligence' ? 'active' : ''}`}
                              onClick={() => handleLinkClick('')}>
                            Artificial Intelligence
                        </Link>
                    </li>
                </ul>
                <ul>
                    <button className="action-button" onClick={handleRedirectRondleiding}>
                        Rondleiding<IoEnterOutline className="rondleiding-icon"/>
                    </button>
                </ul>
            </nav>

        </header>
    );


}

export default Header;
