import React from 'react';
import './ActionRouteToConsultancy.css';
import { useNavigate } from "react-router-dom";
import {IoIosInformationCircleOutline} from "react-icons/io"; /* Import Euro icon from react-icons */

function ActionRouteToConsultancy() {
    const navigate = useNavigate();

    function handleRedirectToBetaalplan() {
        navigate('/consultancy');
    }

    return (
        <div className="ActionRouteToConsultancy">
            <button onClick={handleRedirectToBetaalplan} className="action-route-consultancy">
                {/*<BiEuro className="action-route-consultancy-icon" />*/}
                <IoIosInformationCircleOutline className="action-route-consultancy-icon"/>
                Consultancy
            </button>
            <div className="action-route-to-consultancy-content">
                <p className="action-route-to-consultancy">Bekijk voorbereiding op consultancy</p>
            </div>

        </div>
    );
}

export default ActionRouteToConsultancy;