import React from 'react';
import './ActionAPI.css';
import { useNavigate } from 'react-router-dom';
import ActionRouteToPricing from "./ActionRouteToPricing";
import ActionRouteToConsultancy from "./ActionRouteToConsultancy";

function ActionAPI() {
    const navigate = useNavigate();

    function handleRedirect() {
        navigate('/api-docs');
    }

    return (
        <div className="ActionAPI">
            <div className="api-container">
                <h1>API</h1>
                <h2 className="api-title">Ontwikkeling, Onderhoud en/of Optimalisatie</h2>
                <p className="api-description">
                    Wij bieden robuuste en schaalbare API-oplossingen om uw applicaties te versterken. Ons team van experts helpt u bij het ontwikkelen, implementeren en onderhouden van API's die naadloos integreren met uw systemen.
                </p>
                <h3 className="api-subtitle">Onze Diensten</h3>
                <ul className="api-list">
                    <li>Ontwerp en ontwikkeling van op maat gemaakte API's.</li>
                    <li>Integratie van API's met bestaande systemen.</li>
                    <li>Onderhoud en ondersteuning van API's.</li>
                    <li>Optimalisatie en schaalbaarheid van API's.</li>
                    <li>Veiligheid en authenticatie van API's.</li>
                </ul>
                <h3 className="api-subtitle">Hoe te Beginnen</h3>
                <p className="api-description">
                    Volg deze stappen om te beginnen:
                </p>
                <ul className="api-list">
                    <li>Definieer uw API-vereisten en doelstellingen.</li>
                    <li>Verzamel relevante documentatie en gegevens over uw project.</li>
                    <li>Maak een lijst van de specifieke functies en eindpunten die u nodig heeft.</li>
                    <li>Plan een bespreking met ons team om uw vereisten te bespreken.</li>
                </ul>
                {/*<button*/}
                {/*    className="api-docs-button"*/}
                {/*    onClick={handleRedirect}*/}
                {/*>*/}
                {/*    Bekijk API Documentatie*/}
                {/*</button>*/}
                <ActionRouteToConsultancy />

                <h3 className="api-subtitle">Privacy en Beveiliging</h3>
                <p className="api-description">
                    Uw gegevens en privacy zijn voor ons van het grootste belang. Alle informatie die tijdens onze samenwerking wordt gedeeld, wordt strikt vertrouwelijk behandeld en beveiligd.
                </p>
                <ActionRouteToPricing />

                <h3 className="api-subtitle">Prijzen</h3>
                <p className="api-description">
                    Onze prijzen zijn flexibel en afhankelijk van uw specifieke behoeften. Voor een gedetailleerde offerte, neem contact met ons op.
                </p>
            </div>
        </div>
    );
}

export default ActionAPI;

//
{/*<Route path="/starters" element={<Starters />} />*/}


